import React from 'react';
import PageHeader from '../components/PageHeader';

export default function ErrorPage() {

    return (
        <div>
            <PageHeader title='Whoops!' subtitle='We couldn&#39;t find what you were looking for.'/>
        </div>
    )
}