import React from 'react'

interface TextBlockProps {
    text: (string|{text: string, indent: boolean})[]
    wrapperClassName?: string
}

export default function TextBlock(props: TextBlockProps) {
    
    function renderContent(content: string|{text: string, indent: boolean}) {
        var classes = 'block has-text-justified'
        var text
        if (typeof content !== 'string') {
            text = content.text
            if (content.indent) {
                classes = 'block has-text-justified has-text-weight-bold'
            }
        } else {
            text = content
        }
        return <div className={classes}>{text}</div>
    }

    return (
        <div className={props.wrapperClassName}>
            {
                props.text.map(content =>
                    renderContent(content)
                )
            }
        </div>
    )
}