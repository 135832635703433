import React from 'react'
import TextBlock from '../components/TextBlock';
import RoomCard from '../components/RoomCard';

export default function HomePage() {
    const whatIsItContent = [
        "Gamification of core national curriculum content for students. Hands on puzzles that help students revise key topics by testing them from within an escape room style challenge environment.",
        "Not only helping students to revise content in a fun and engaging way, but also facilitating development of students' soft skills; communication, working as a team and critical thinking. Building their resilience, whilst covering exam-based content in a new and memorable way.",
        "We bring premium quality props and puzzle boxes into schools, setting them up for groups of students to take on. We also bring ambient lighting and music to make the sessions feel different from a standard classroom lesson and are able to differentiate the game for different group needs.",
        "After each session we do a group debrief to go over the puzzles and revisit the content covered. We like the students to explain to their teammates how they solved a puzzle, to build their confidence in and solidify their content knowledge. This gives an opportunity to fill in gaps in knowledge for their peers.",
        "Finally, we provide a revision pack for the unit which can be completed before or after the experience, as a useful resource to aid students in revising for exams.",
      ]
    
    return (
        <div>
            <section className='video-banner'>
                <div className='video-wrapper'>
                    <video
                        className='video'
                        controlsList="nodownload"
                        poster='/eduscape-video.jpg'
                        preload='auto'
                        autoPlay
                        loop
                        playsInline
                        muted>
                        <source src='/eduscape.mp4' type="video/mp4"/>
                        Your browser does not support the video tag.
                    </video> 
                    <div className='is-overlay video-overlay'>
                        <div className='has-text-centered'>
                            <div className='fifth-width centered'>
                                <figure className='image is-square'>
                                    <img src='/eduscape.png' alt='logo'></img>
                                </figure>
                            </div>
                            <h1 className='title has-text-white is-size-5-mobile is-size-2-desktop'>
                                Pop-up Educational Escape Room for Schools<br/>
                                Bringing fun and hands on learning into the classroom
                            </h1>
                        </div>
                    </div>
                </div>

            </section>
            <section className='hero is-primary is-small'>
                <div className='hero-body has-text-centered'>
                    <h1 className='title'>Our Experiences</h1>
                    <div className='tile is-ancestor'>
                        <div className='tile is-1'/>
                        <div className="tile is-parent is-4">
                            <div className="tile is-child card-tile">
                                <RoomCard title='GCSE Biology' imagePath='./biology.png' altText='Biology escape room puzzle' subtitle='Cells and Transport' button/>
                            </div>
                        </div>
                        <div className='tile is-2'/>
                        <div className="tile is-parent is-4">
                            <div className="tile is-child card-tile">
                                <RoomCard title='GCSE Physics' imagePath='./new_course.jpg' altText='Placeholder image'/>
                            </div>
                        </div>
                        <div className='tile is-1'/>
                    </div>
                </div>
            </section>
            <section className='section'>
                <div className='container'>
                    <h1 className='title'>What do we provide</h1>
                    <TextBlock text={whatIsItContent}/>
                </div>
            </section>
            <section>
                <div className='hero is-primary is-small'>
                    <div className='hero-body has-text-centered'>
                    <h1 className='title'>Our Experiences</h1>
                        <div className='columns is-centered is-mobile home-gallery'>
                            <div className='column'>
                                <figure className="image is-3by2">
                                    <img src="./eduscape_in_action_1.jpg" alt='eduscape in action'/>
                                </figure>
                            </div>
                            <div className='column'>
                                <figure className="image is-2by3">
                                    <img src="./eduscape_in_action_2.jpg" alt='eduscape in action'/>
                                </figure>
                            </div>
                            <div className='column'>
                                <figure className="image is-2by3">
                                    <img src="./eduscape_in_action_3.jpg" alt='eduscape in action'/>
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

    )
}