import React, { useState } from 'react';
import { Link } from 'react-router-dom';

interface iNavBarProps {
    menuItems: {text: string, uri: string}[]
}

export default function NavBar(props: iNavBarProps) {

    const [isActive, toggleActive] = useState(false)
    var isActiveClass = isActive ? ' is-active' : ''

    return (
        <nav className='navbar' role='navigation' aria-label='main navigation'>
            <div className='navbar-brand'>
            <Link className='navbar-item is-size-4' to='/' onClick={() => toggleActive(false)}>Eduscape Club</Link>
            <a role='button' className={'navbar-burger' + isActiveClass} aria-label='menu' aria-expanded='false' onClick={() => toggleActive(!isActive)}>
                <span aria-hidden='true'></span>
                <span aria-hidden='true'></span>
                <span aria-hidden='true'></span>
            </a>
            </div>
            <div className={'navbar-menu' + isActiveClass}>
                <div className='navbar-end'>
                    {props.menuItems.map( item =>
                        <Link className='navbar-item is-size-5' to={item.uri} onClick={() => toggleActive(false)}>{item.text}</Link>
                    )}
                </div>
            </div>
      </nav>
    )
}
