import React from 'react'

export default function PageFooter() {
    return (
        <footer className='footer is-primary page-footer'>
            <div className='columns'>
                <div className='column is-6'>
                    <p className='subtitle'><b>Contact:</b></p>
                    <p><b>Email:</b> hello@eduscape.club</p>
                </div>
                <div className='column is-6'>
                    <p className='subtitle'><b>Areas Covered:</b></p>
                    <p>Bedfordshire, Berkshire, Buckinghamshire, East Sussex, Essex, Hampshire, Hertfordshire, Kent, London, Oxfordshire, Surrey, West Sussex</p>
                </div>
            </div>
        </footer>
    )
}
