import React from 'react';
import PageHeader from '../components/PageHeader';
import TextBlock from '../components/TextBlock';

export default function ContactPage() {
    const contactContent = [
        'We would love to work with you to provide engaging, curriculum based escape room experiences to your students. If you’d like to organise it for your school, get more details, or just have a chat, then please drop us a message via email and we’ll be in touch:',
        {text: 'Our email is: hello@eduscape.club', indent: true},
        'And if you’d like to see what we do, in person, then visits to our site or demos can be arranged (but please get in contact with us and book an appointment in advance).',
        {text: 'Our address is: 94 Gloucester Road, Brighton, BN1 4AP', indent: true},
        'Emily and team are looking forward to hearing from you!'
    ]

    return (
        <div>
            <PageHeader title='Contact us'/>
            <div className='container'>
                <section className='section'>
                    <TextBlock text={contactContent}/>
                </section>
            </div>
        </div>
    )
}