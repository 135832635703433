import React from 'react'
import { Link } from 'react-router-dom'

interface PageHeaderProps {
    title: string
    subtitle?: string
}

export default function PageHeader(props: PageHeaderProps) {
    return (
        <section className='hero is-primary py-5'>
            <div className="columns is-centered is-mobile">
                <div className='column is-narrow'>
                    <figure className='image is-96x96 header-image'>
                        <Link to='/'>
                            <img src='/eduscape.png' alt='logo'/>
                        </Link>
                    </figure>  
                </div>
                <div className='column is-three-quarters header-title'>
                    <h1 className='title'>{props.title}</h1>
                    {
                        props.subtitle && 
                        <h2 className='subtitle'>{props.subtitle}</h2>
                    }
                </div>
            </div> 
        </section>
    )
}