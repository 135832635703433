import React from 'react';
import PageHeader from '../components/PageHeader';
import TextBlock from '../components/TextBlock';

export default function FranchisePage() {
    const franchiseContent = [
        'Eduscape is a fantastic and satisfying business to bring fun and games to schools and make a real impact on kids’ learning.',
        'We’re on the lookout for operators in different parts of the country to help us take this product nationwide.',
        'It can work as a standalone part-time business, or as a fab addition to an existing escape room business or school activity providers.',
        'Also talk to us about our other product - Puzzle Pals - custom designed escape room and puzzle solving after-school clubs for primary schools.',
    ]

    return (
        <div>
            <PageHeader title='Franchise'/>
            <div className='container'>
                <section className='section'>
                    <TextBlock text={franchiseContent}/>
                </section>
            </div>
        </div>
    )
}