import React from 'react'

interface ExperienceCardProps {
    title: string
    imagePath: string
    altText: string
    difficulty?: string
    button?: boolean
    summary?:string
    specification?:string[]
}

export default function ExperienceCard(props: ExperienceCardProps) {
    return (
        <div className='card transparent-background'>
            <div className="card-image">
                <figure className="image is-5by3">
                    <img src={props.imagePath} alt={props.altText}/>
                </figure>
            </div>
            <div className='card-content'>
                <div>
                    <p className="title has-text-black">{props.title}</p>
                </div>
                <div>
                    {props.summary}
                </div>
                { props.difficulty && <p className='has-text-black'><b>Difficulty: </b>{props.difficulty}</p> }
                { props.specification &&
                    <div>
                        <p className='has-text-black'><b>Specification:</b></p>
                        <ul className='specification-list pl-4'>
                            {
                                props.specification.map(content =>
                                    <li className='has-text-black'>{content}</li>
                                )
                            }
                        </ul>
                    </div>
                }
            </div>


        </div>
    )
}