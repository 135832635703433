import React from 'react'

interface ChildTileProps {
    title: string
    content?: string[]
    size?: string
}

export default function ChildTile(props: ChildTileProps) {
    return (
        <div className={"tile is-parent " + props.size}>
            <div className="tile is-child box notification is-primary is-light has-text-black has-border notification-padding">
                <p className="subtitle">{props.title}</p>
                {
                    props.content &&
                    props.content.map(it =>
                        <div>{it}</div>
                    )
                }
            </div>
        </div>
    )
}