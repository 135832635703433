import React from 'react';
import './App.css';
import './css/theme.css';
import NavBar from './components/NavBar';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import ErrorPage from './pages/ErrorPage';
import AboutPage from './pages/AboutPage';
import GamesPage from './pages/ExperiencesPage';
import LogisticsPage from './pages/LogisticsPage';
import ContactPage from './pages/ContactPage';
import FranchisePage from './pages/FranchisePage';
import PageFooter from './components/PageFooter';

function App() {
  const menuItems = [
    {text: 'About Us', uri: '/about', element:<AboutPage/>},
    {text: 'Our Experiences', uri: '/our-experiences', element: <GamesPage/>},
    {text: 'Logistics', uri: '/logistics', element: <LogisticsPage/>},
    {text: 'Contact', uri: '/contact', element: <ContactPage/>},
    {text: 'Franchise', uri: '/franchise', element: <FranchisePage/>},
  ]

  return (
    <div className="App">
      <link rel="stylesheet" href="css/theme.css"/>
      <div className='content-wrapper'>
        <BrowserRouter>
          <NavBar menuItems={menuItems}/>
          <Routes>
            <Route path='/' element={<HomePage/>}/>
            {
              menuItems.map(item =>
                <Route path={item.uri} element={item.element}/>
              )
            }
            <Route path='*' element={<ErrorPage/>}/>
          </Routes>
        </BrowserRouter>
      </div>
      <PageFooter/>
    </div>
  );
}

export default App;
